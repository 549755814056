import { useState, useEffect, useContext, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCaretUp, faMultiply } from '@fortawesome/free-solid-svg-icons'
import { ProjectVar, isObjectEmpty } from '../../../utils'
import { Auth } from '../../../context'
import ProfileNav from './ProfileNav'

const MainNav = () => {
    const [menu, setMenu] = useState(false)
    const [showBar, setShowBar] = useState(true)
    const [topRatedMenuOpen, setTopRatedMenuOpen] = useState(false)

    const { state } = useContext(Auth)

    useEffect(() => {
        if (window.visualViewport.width > 450) {
            setMenu(true)
            setShowBar(false)
        }
    }, [setMenu])

    const menuData = [
        { txt: 'Find Events (CEs)', lnk: '/events' },
        { txt: 'Virtual CE', lnk: '/virtualce' },
        {
            txt: 'Mentorship',
            lnk: '/mentorship',
            // submenu: [
            //     { txt: 'Mentors', lnk: '/' },
            //     { txt: 'Mentees', lnk: '/' },
            // ],
        },
        { txt: 'Dental Deals', lnk: '/dentaldeals' },
        {
            txt: 'Top Rated',
            lnk: '/top-rated/',
            submenu: [
                { txt: 'Events', lnk: '/top-rated/events' },
                { txt: 'Organizers', lnk: '/top-rated/organizers' },
                { txt: 'Speakers', lnk: '/top-rated/speakers' },
            ],
        },
    ]

    const pathLocation = useLocation()
    const path = pathLocation.pathname

    const ref = useRef(null)

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setTopRatedMenuOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [])

    return (
        <div className="py-6 lg:px-4 xl:px-20 grid grid-cols-12 shadow-md bg-gradient-to-bl from-primary-green-1 to-dark-green backdrop-blur-md">
            <div className="inline col-span-12 md:col-span-3 relative pl-8 md:pl-0">
                <Link to="/" className="inline-block">
                    <img src={ProjectVar.PROJECT_LOGO_WHITE} alt={ProjectVar.PROJECT_NAME} className="max-w-[140px]" />
                </Link>

                {showBar ? (
                    <span>
                        <span className=" absolute right-14 top-2">
                            {isObjectEmpty(state.auth) === false ? <ProfileNav /> : null}
                        </span>
                        <span className="absolute right-6 top-2" onClick={() => setMenu(!menu)}>
                            {menu ? (
                                <FontAwesomeIcon icon={faMultiply} className="text-white scale-125" />
                            ) : (
                                <FontAwesomeIcon icon={faBars} className="text-white scale-125" />
                            )}
                        </span>
                    </span>
                ) : null}
            </div>
            {menu ? (
                <>
                    <div className="relative h-100 col-span-12 md:col-span-6 text-center pt-4 md:pt-1">
                        {menuData.map((v, i) => {
                            return v?.submenu ? (
                                <>
                                    <div
                                        key={i}
                                        onClick={() => setTopRatedMenuOpen(!topRatedMenuOpen)}
                                        className={
                                            path.includes(v.lnk)
                                                ? 'cursor-pointer golden-txt font-medium hover:golden-txt px-8 md:px-4 text-base lg:text-sm xl:text-lg text-left md:text-center block md:inline mb-2'
                                                : 'cursor-pointer text-white font-medium hover:golden-txt px-8 md:px-4 text-base lg:text-sm xl:text-lg text-left md:text-center block md:inline mb-2'
                                        }>
                                        {v.txt}
                                    </div>
                                    {topRatedMenuOpen && (
                                        <div
                                            className="absolute flex flex-col right-10 top-12 shadow-md border-2 bg-white border-gray-200 px-6 py-4 rounded-md min-w-48 z-50"
                                            ref={ref}>
                                            {v?.submenu.map((sv, si) => (
                                                <Link
                                                    key={si}
                                                    to={sv.lnk}
                                                    className={
                                                        path === sv.lnk
                                                            ? 'golden-txt text-sm font-medium hover:text-primary-green-2 px-8 md:px-4 lg:text-sm xl:text-lg text-left block md:inline mb-2'
                                                            : 'text-[#0d271e] text-sm font-medium hover:text-primary-green-2 px-8 md:px-4 lg:text-sm xl:text-lg text-left block md:inline mb-2'
                                                    }>
                                                    {sv?.txt}
                                                </Link>
                                            ))}
                                            <FontAwesomeIcon
                                                icon={faCaretUp}
                                                className="absolute text-white text-4xl -top-4 right-2 md:right-8 -z-10"
                                            />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <Link
                                    reloadDocument
                                    key={i}
                                    to={v.lnk}
                                    className={
                                        path === v.lnk
                                            ? 'golden-txt font-medium hover:golden-txt px-8 md:px-4 text-base lg:text-sm xl:text-lg text-left md:text-center block md:inline mb-2'
                                            : 'text-white font-medium hover:golden-txt px-8 md:px-4 text-base lg:text-sm xl:text-lg text-left md:text-center block md:inline mb-2'
                                    }>
                                    {v.txt}
                                </Link>
                            )
                        })}
                    </div>
                    <div className="h-100 col-span-12 md:col-span-3 relative">
                        {isObjectEmpty(state.auth) === true ? (
                            <div className="float-left md:float-right pl-6 md:pl-0 mt-2 md:mt-0">
                                <Link
                                    to="/login"
                                    className="border-2 border-primary-golden-4 text-sm lg:text-sm xl:text-lg text-primary-golden-4 font-medium px-6 md:px-4 lg:px-4 xl:px-8 py-2 md:py-3 rounded-lg mr-2">
                                    Log in
                                </Link>
                                <Link
                                    to="/signup"
                                    className="golden border-2 border-primary-golden-5 text-sm lg:text-sm xl:text-lg text-primary-green-1 font-medium px-6 md:px-4 lg:px-4 xl:px-8 py-2 md:py-3 rounded-lg ml-2">
                                    Sign up
                                </Link>
                            </div>
                        ) : (
                            <div className="w-full inline-block">
                                <div className="absolute top-0 md:top-0 md:right-16 pl-8 hidden md:inline-block">
                                    <ProfileNav />
                                </div>
                            </div>
                        )}
                    </div>
                </>
            ) : null}
        </div>
    )
}

export default MainNav
