import { MainNav } from '@components/molecules'
import { Header } from '@components/organisms'
import React from 'react'
import TopRatedImg from '../../assets/img/top-rated.jpg'

export default function TopRatedPage() {
    return (
        <div>
            <Header title="Top Rated" />
            <MainNav />
            <div className="w-full h-96">
                <img src={TopRatedImg} alt="Top Rated" className="w-full h-full object-cover" />
            </div>
        </div>
    )
}
